import React from "react"

const startAnimation = () => {
  console.log("Matrix script loaded.")

  class Symbol {
    constructor(x, y, fontSize, canvasHeight) {
      this.characters = 'アァカサタナハマヤャラワガザダバパイィキシチニヒミリヰギジヂビピウゥクスツヌフムユュルグズブヅプエェケセテネヘメレヱゲゼデベペオォコソトノホモヨョロヲゴゾドボポヴッン0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      this.x = x;
      this.y = y;
      this.fontSize = fontSize;
      this.text = '';
      this.canvasHeight = canvasHeight;
    }

    draw(context) {
      this.text = this.characters.charAt(Math.floor(Math.random() * this.characters.length));
      context.fillText(this.text, this.x * this.fontSize, this.y * this.fontSize);
      if (this.y * this.fontSize > this.canvasHeight && Math.random() > 0.97) {
        this.y = 0;
      } else {
        this.y += 1;
      }
    }
  }

  class Effect {
    constructor(canvasWidth, canvasHeight) {
      this.#initialize(canvasWidth, canvasHeight);
    }
    
    #initialize(width, height) {
      this.fontSize = 25;
      this.canvasWidth = width;
      this.canvasHeight = height;
      this.columns = this.canvasWidth / this.fontSize;
      this.symbols = [];
      for (let i = 0; i < this.columns; i++) {
        this.symbols[i] = new Symbol(i, 0, this.fontSize, this.canvasHeight);
      }
    }

    resize(width, height) {
      this.#initialize(width, height);
    }
  }

  console.log("Clicked!");
  const canvas = document.createElement('canvas');
  document.body.prepend(canvas);
  const ctx = canvas.getContext('2d');
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  // Init Matrix effect and animation configuration.
  const effect = new Effect(canvas.width, canvas.height);
  let lastTime = 0;
  const fps = 15;
  const nextFrame = 1000/fps;
  let timer = 0;

  window.addEventListener('resize', function() {
    console.log("Resized window!");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    effect.resize(canvas.width, canvas.height);
  });

  function animate(timeStamp) {
    console.log("Animating!");
    const deltaTime = timeStamp - lastTime;
    lastTime = timeStamp;
    if (timer > nextFrame) {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.09)';
      ctx.textAlign = 'center';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#0aff0a'; 
      ctx.font = effect.fontSize + 'px monospace';
      effect.symbols.forEach(symbol => symbol.draw(ctx));
      timer = 0;
    } else { 
      timer += deltaTime;
    }
  
    requestAnimationFrame(animate);
  }
  animate(0);
}

const MatrixText = () => <div onClick={() => startAnimation()} style={{cursor: "pointer"}}>🐇</div>

export default MatrixText;
